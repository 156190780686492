import { Cloudinary } from "@cloudinary/url-gen";

export const cld = new Cloudinary({
  cloud: {
    cloudName: "dvajyzict",
  },
});

export const getOriginalCloudinaryImageUrl = (publicId) => {
  const cloudName = "dvajyzict"; // Move to ENV
  const baseUrl = `https://res.cloudinary.com/${cloudName}/image/upload/${publicId}.jpg`;
  const url = new URL(baseUrl);
  return url.origin + url.pathname; // This will return the URL without query parameters
};
