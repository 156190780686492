import React from "react";
import "./ApplicantConsent.css";

function ApplicantConsent() {
  return (
    <div className="applicant-consent">
      <h2>Muslims Achieving Excellence Scholarship Fund Applicant Consent</h2>

      <p>
        For the application to be considered, the applicant must acknowledge
        that they have read the following and consent to the information below.
      </p>

      <h3>General Guideline Overview:</h3>
      <ol>
        <li>
          Applicants may apply to only 2 scholarship subcategories. There can be
          more than one scholarship in a single subcategory. Applicants are
          eligible for all scholarships in each of the 2 subcategories that they
          apply for.
        </li>
        <li>
          A previous winner of a Muslims Achieving Excellence (MAX) Scholarship
          is not eligible for the same scholarship the following year, however,
          may be eligible for any other scholarship (up to a lifetime of 3
          scholarships total).
        </li>
        <li>
          The MAX Scholarship Fund (MSF) Committee and judging panel have the
          authority to remove an applicant at any point in the process, without
          notice and at their discretion.
        </li>
        <li>
          Applicants understand that there will be <strong>NO</strong>{" "}
          exceptions for late submissions.
        </li>
        <li>
          Applicants are encouraged to write their application answers in a word
          document, and review them thoroughly before inputting into the
          application portal. Some application questions are standard across the
          subcategories and applicants may use the same answer in such
          instances. Please note that once an application has been submitted, it
          cannot be edited.
        </li>
        <li>
          Applicants are required to write in full sentences and in paragraph
          form. No bullet points shall be accepted when responding to an essay
          question, unless otherwise indicated in the question as acceptable.
        </li>
        <li>
          Applicants understand that they are unable to submit an application
          until all required essay questions have been answered, and both
          reference letters have been uploaded. It is the applicant's
          responsibility to ensure their referees are provided enough time to
          complete their reference letters. There will be no exceptions for late
          submissions.
        </li>
        <li>
          Applicants appreciate that Muslims Achieving Excellence (MAX) is a
          special movement built and run by volunteers. Applicants understand
          that if they win a MAX Scholarship, they will do their part and
          contribute back to the community.
        </li>
        <li>
          Applicants understand that by applying to a MAX scholarship, their
          email may be added to MAX's email distribution list and that they can
          unsubscribe at any time.
        </li>
      </ol>

      <h3>
        The following person(s)/entities are ineligible to apply for a MAX
        Scholarship:
      </h3>
      <ol>
        <li>Any member of the MAX/MSF Advisory Board.</li>
        <li>
          Any member of the MAX Executive Committee, unless they are far removed
          from the application and adjudication scholarship process.
        </li>
        <li>
          Any member of the MSF volunteer team, unless they are far removed from
          the application and adjudication scholarship process.
        </li>
        <li>A scholarship sponsor may not apply for their own scholarship.</li>
        <li>
          Immediate relatives of a scholarship sponsor (defined as spouse,
          children, parents and siblings) are ineligible to be the recipient of
          this scholarship. They are eligible for any other scholarship.
        </li>
      </ol>

      <h3>The applicant acknowledges that:</h3>
      <ol>
        <li>The information on their application is true and correct.</li>
        <li>
          They give their permission for the information in the scholarship
          application to be shared with the individuals associated with the
          judging and interview process.
        </li>
        <li>
          They understand that the information provided in the scholarship
          application will not be shared or sold to any third party.
        </li>
        <li>
          They waive their right to view any letters of recommendation or other
          endorsements which are submitted on their behalf.
        </li>
        <li>
          They agree that if they are selected for an interview, they may be
          invited to participate in an online interview and will require the use
          of videoconferencing technology.
        </li>
        <li>
          They release the right to use their name and other information
          required for the announcement of winners on social media
          posts/marketing materials.and for the purpose of fund transfer.
        </li>
        <li>
          They understand that it is their responsibility to ensure that the
          application is submitted by the deadline, adheres to the word limits
          and includes all required documents and responses. There will be no
          late submissions and incomplete applications will be disqualified.
        </li>
        <li>
          They are asked to add scholarships@maxscholars.org to their safe
          senders list. Applicants will be notified of next steps and contacted
          should they be successful in advancing to the next stage of the
          interview process. They will be responsible to respond when/if
          required. If they do not respond when/if required, they will forego
          their spot and another applicant will be considered for next steps.
          Applicants are asked not to email asking about next steps, MSF will
          contact them.
        </li>
        <li>
          They are required to take a look at the list of Scholarships and the
          Frequently Asked Questions before beginning their application.
        </li>
      </ol>

      <h3>Unconscious or Implicit Bias</h3>
      <p>
        MSF is committed to ensuring a fair application process. To reduce
        unconscious or implicit bias, applicants are asked to minimize reference
        to their cultural background, age, race, language and gender and remove
        proper nouns and names of people in their application essay answers.
      </p>

      <h3>Equity, Diversity and Inclusion</h3>
      <p>
        The MAX Scholarship Fund (MSF) is committed to providing an environment
        that fosters the growth and development of all individuals, regardless
        of their physical, cultural or social characteristics, to support
        innovation and the realization of the full potential of all applicants,
        volunteers and individuals.
      </p>

      <h3>Accommodation</h3>
      <p>
        The MAX Scholarship Fund (MSF) is committed to providing an inclusive
        and barrier-free application process. If you require accommodations to
        complete your application, please inform us by email at
        scholarships@maxscholars.org as soon as possible, and no later than
        March 15, so that we may make appropriate and reasonable arrangements.
        All information received in relation to an accommodation will remain
        confidential.
      </p>
      <p>
        We encourage applicants who require accommodations to reach out to
        external organizations such as the Canadian National Institute for the
        Blind (CNIB), SMILE Canada, DEEN Support Services, for support during
        the application process.
      </p>

      <h3>Failure to Complete the Application</h3>
      <p>
        If you fail to complete and submit the application before the due date,
        your application will not be considered further in this process. No
        exceptions will be made. If your application is incomplete, it will be
        disqualified.
      </p>

      <h3>Upcoming Steps</h3>
      <p>
        Each application will be judged by a panel of judges in April/May.
        Selected applicants will then be invited to an interview
        (videoconference). Selected applicants will receive an invitation to
        this interview in May/June. Winners will be notified in July/August. A
        reception will be held in September. If timelines change, applicants
        will be notified.
      </p>
    </div>
  );
}

export default ApplicantConsent;
