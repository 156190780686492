import axios from "axios";

const baseURL = window.location.origin.includes("localhost")
  ? "http://localhost:8000"
  : "https://maxscholars.org";

export const getCurrentScholarshipsCycle = async () => {
  try {
    const currentScholarshipsCycle = await axios.get(
      baseURL + "/api/scholarships/scholarship_cycles/current/"
    );
    return Promise.resolve(currentScholarshipsCycle.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getScholarships = async () => {
  try {
    const getCurrentCycle = await axios.get(
      baseURL + "/api/scholarships/scholarship_cycles/current/"
    );
    const response = await axios.get(
      baseURL + `/api/scholarships?cycle=${getCurrentCycle.data.id}`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getScholarshipDetails = async (id) => {
  try {
    const response = await axios.get(baseURL + `/api/scholarships/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSponsors = () => {
  return axios.get(baseURL + "/api/scholarships/sponsors/");
};

export const getPastWinners = () => {
  return axios.get(baseURL + "/api/winners");
};
