import { isMobile, isTablet } from "react-device-detect";
import Carousel from "react-bootstrap/Carousel";
import { Card } from "react-bootstrap";
import React, { Component, useEffect, useState } from "react";
import sponsorsList from "./sponsors-list.json";
import { getOriginalCloudinaryImageUrl } from "../../utils/CloudinaryService";
import imagePathSponsorList from "./imagePathSponsor.json";
import {
  findClosestMatch,
  fetchImagePaths,
  returnSponsorNamesList,
} from "../../utils/StringUtils";
import { getSponsors } from "../../utils/BackendService";

const MobileSponsor = () => {
  const [sponsorImagePaths, setSponsorImagePaths] = useState([]);
  const [sponsorNamesList, setSponsorNamesList] = useState([
    {
      id: 1,
      full_name: "Dr. Ike Ahmed",
      bio: "",
      photo: "sponsor_picture/Ike_Ahmed_University_Entrance_Scholarship.jpg",
      city: null,
      cell_number: null,
      industry: null,
      job_title: null,
      company: null,
      non_public_profile: true,
    },
  ]);

  const fetchSponsors = async () => {
    getSponsors().then((res) => {
      setSponsorNamesList(res.data);
    });
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  // const callFetchImagePaths = async () => {
  //   const data = await fetchImagePaths("sponsor");
  //   if (data) {
  //     setSponsorImagePaths(data);
  //   }
  // };
  // const callFetchsponsorNamesList = async () => {
  //   const data = await returnSponsorNamesList();
  //   if (data) {
  //     setSponsorNamesList(data);
  //   }
  // };

  // useEffect(() => {
  //   callFetchImagePaths();
  //   callFetchsponsorNamesList();
  // }, []);

  // useEffect(() => {
  //   console.log(
  //     findClosestMatch(
  //       "Yumnah Afzaal Memorial All Star Scholarship",
  //       "sponsor",
  //       sponsorImagePaths
  //     )
  //   );
  // });

  // if (isMobile || isTablet) {
  return (
    <>
      <Carousel interval="1200">
        {sponsorNamesList.map((sponsorName, id) => (
          <Carousel.Item key={id}>
            <div>
              <div className="carousel-img-boxy">
                <Card>
                  {/* <img className="carousel-img-box" src={sponsor3} /> */}
                  <img
                    className="carousel-img-box"
                    // src={findClosestMatch(
                    //   sponsorName,
                    //   "sponsor",
                    //   sponsorImagePaths
                    // )}
                    src={"/media/" + sponsorName.photo}
                  />
                  <span className="carousel-text-box">
                    {sponsorName.full_name}
                  </span>
                </Card>
              </div>
            </div>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
  // } else {
  //   return (
  //     <>
  //       <Carousel>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(0, 5).map((sponsorName) => (
  //                 <Card>
  //                   {sponsorName.includes("Hifz") ? (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={getOriginalCloudinaryImageUrl("Hifzacademy.png")}
  //                     />
  //                   ) : (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={findBestImageMatch(sponsorName)}
  //                     />
  //                   )}
  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(5, 10).map((sponsorName) => (
  //                 <Card>
  //                   {sponsorName.includes("Maimoona") ? (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={getOriginalCloudinaryImageUrl(
  //                         "MaimoonaWasiAhmed.jpeg"
  //                       )}
  //                     />
  //                   ) : (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={findBestImageMatch(sponsorName)}
  //                     />
  //                   )}

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(10, 15).map((sponsorName) => (
  //                 <Card>
  //                   {sponsorName.includes("Rashida Abdullah") ? (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={getOriginalCloudinaryImageUrl(
  //                         "RashidaAbdullah.jpeg"
  //                       )}
  //                     />
  //                   ) : (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={findBestImageMatch(sponsorName)}
  //                     />
  //                   )}

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(15, 20).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(20, 25).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(25, 30).map((sponsorName) => (
  //                 <Card>
  //                   {sponsorName.includes("Safari") ? (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={getOriginalCloudinaryImageUrl(
  //                         "HudHud-Scholarship-For-Somali-Canadian-Youth.png"
  //                       )}
  //                     />
  //                   ) : (
  //                     <img
  //                       className="carousel-img-box"
  //                       src={findBestImageMatch(sponsorName)}
  //                     />
  //                   )}

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(30, 35).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(35, 40).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(40, 45).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(45, 50).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //         <Carousel.Item>
  //           <div>
  //             <div className="carousel-img-boxy">
  //               {sponsorsList.slice(50, 55).map((sponsorName) => (
  //                 <Card>
  //                   <img
  //                     className="carousel-img-box"
  //                     src={findBestImageMatch(sponsorName)}
  //                   />

  //                   <span className="carousel-text-box">{sponsorName}</span>
  //                 </Card>
  //               ))}
  //             </div>
  //           </div>
  //         </Carousel.Item>
  //       </Carousel>
  //     </>
  //   );
  // }
};
export default MobileSponsor;
