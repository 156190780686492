import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  getScholarshipDetails,
  getScholarships,
} from "../../utils/BackendService";
import scholarshipDetails from "../Scholarships/scholarships-data.json";
import "./ScholarshipDetail.css";
import sponsorImagesAddresses from "./sponsor-images-address.json";
import { findClosestMatch, fetchImagePaths } from "../../utils/StringUtils";
import HeaderHome from "../../components/HeaderHome/HeaderHome";

function ScholarshipDetail() {
  const [scholarshipData, setScholarshipData] = useState([]);
  const [scholarshipDetail, setScholarshipDetail] = useState({
    id: 52,
    sponsor: {
      id: 1,
      full_name: "123Dr. Ike Ahmed",
      bio: "",
      photo: "sponsor_picture/Alsafa-Foods-Canada-Ltd.jpeg",
      city: null,
      cell_number: null,
      industry: null,
      job_title: null,
      company: null,
      non_public_profile: true,
    },
    subcategory: {},
    name: "Ike Ahmed University Entrance Scholarship",
    description:
      '"Dr. Ike Ahmed inspired a whole generation of Muslim high school and university students through the example he set for others to follow. He excelled at academics, gained admission to Medical School at the highly competitive University of Toronto, and concurrently led Muslim organizations on campus. His on-campus leadership included organizing some of the first Canadian Muslim basketball tournaments in Toronto while being immersed in his studies and clinical rotations.\r\n\r\nDr. Ahmed is now an Ophthalmologist and is recognized as one of the world’s most experienced complex eye surgeons. He is known not only for being at the leading edge of innovative treatments but for developing new techniques and surgical devices. Other eye surgeons now reach for Ahmed Diamond Knives and the Ahmed Segment. He is also on the editorial board of six ophthalmology journals and has presented at over 550 medical conferences in Canada and around the world."',
    award_amount: 10000,
    number_of_awards: 1,
    image: "scholarship_images/Ike_Ahmed_University_Entrance_Scholarship.jpg",
  });
  const location = useLocation();
  let { pk } = useParams();
  const [pkey, setPk] = useState({ pk });

  const fetchScholarship = async () => {
    getScholarshipDetails(pk).then((res) => {
      setScholarshipDetail(res);
    });
    // .catch((err) => console.log(err));
  };

  const formatDollar = (num) => {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      maximumSignificantDigits: 3,
    }).format(num);
  };

  useEffect(() => {
    setScholarshipDetail({});
    fetchScholarship();
  }, []);

  // useEffect(() => {
  //   console.log(scholarshipDetail);
  // }, [scholarshipDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [scholarshipImagePaths, setScholarshipWinnerImagePaths] = useState([]);

  // const callFetchImagePaths = async () => {
  //   const data = await fetchImagePaths("sponsor");
  //   if (data) {
  //     setScholarshipWinnerImagePaths(data);
  //   }
  // };

  // useEffect(() => {
  //   callFetchImagePaths();
  // }, []);

  return (
    <>
      {/* <Header title="" backgroundUrl="Sponsor.png" color="white" /> */}
      <HeaderHome backgroundUrl="Sponsor.png" />
      <div style={{ textAlign: "center", marginTop: "3em" }}>
        <h1
          style={{
            color: "#0260A8",
            fontWeight: "bold",
          }}
        >
          {" "}
          {scholarshipDetail?.name || "Scholarship Details"}{" "}
        </h1>
      </div>
      {scholarshipDetail && scholarshipDetail.sponsor ? (
        <>
          <div className="scholarship-detail-main-container">
            <div className="back-btn-container">
              <Link to="/scholarships">
                <button className="scholarship-detail-back-btn">Go Back</button>
              </Link>
            </div>
            <div className="scholarship-detail-header">
              <h3 className="scholarship-detail-header-name">
                {scholarshipDetail.name}
              </h3>
              <h3 className="scholarship-detail-header-amount">
                {formatDollar(scholarshipDetail.award_amount)}
              </h3>
            </div>
            <div className="scholarship-detail-body">
              <div className="scholarship-detail-body-left-pane">
                <img
                  // src={findClosestMatch(
                  //   scholarshipDetail?.name,
                  //   sponsorImagesAddresses
                  // )}
                  // src={findClosestMatch(
                  //   scholarshipDetail.name,
                  //   "sponsor",
                  //   scholarshipImagePaths
                  // )}
                  src={"/media/" + scholarshipDetail?.sponsor.photo}
                  className="winner-modal-img"
                  id="scholar-modal-img"
                />
              </div>
              <div className="scholarship-detail-body-right-pane">
                <p>{scholarshipDetail.description}</p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ScholarshipDetail;
