import { useState } from "react";
import jsonp from "jsonp";

export const MailChimp = () => {
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      "https://maxgala.us11.list-manage.com/subscribe/post-json?u=c170e62e41c55cfbc5b7e6d33&amp;id=ee816450c3&amp;f_id=00777de1f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
      const { msg } = data;

      alert(msg);
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label>Email Address:</label>
        <div
          style={{ paddingTop: "0.5em" }}
          className="d-flex flex-column flex-sm-row w-100 gap-2"
        >
          <input
            type="email"
            name="EMAIL"
            className="form-control"
            placeholder="Your email address..."
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <button className="btn btn-primary footer" type="submit">
            Subscribe
          </button>
        </div>
      </form>
    </div>
  );
};
