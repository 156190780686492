import React, { useState } from "react";
import "./HeaderHome.css";

function HeaderHome({ backgroundUrl }) {
  const [baseURL, setBaseURL] = useState(
    "https://maxscholars.org/static-images/headers"
  );
  // Photo dimensions 500H x 1500W work well
  return (
    <div className="header-container">
      <img className="header-photo" src={baseURL + "/" + backgroundUrl}></img>
    </div>
  );
}

export default HeaderHome;
