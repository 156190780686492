import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import Home from "./Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router";
import Landing from "./pages/Landing/Landing";
import Judge from "./pages/Judge/Judge";
import Financials from "./pages/Financials/Financials";
import FAQ from "./pages/FAQ/FAQ";
import Contact from "./pages/FAQ/faq-contact";
import PastWinners from "./pages/PastWinners/PastWinners";
import Profile from "./pages/Profile/Profile";
import Scholarships from "./pages/Scholarships/Scholarships";
import Sponsor from "./pages/Sponsor/Sponsor";
import Footer from "./components/Footer/Footer";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Header from "./components/Header/Header";
import HeaderHome from "./components/HeaderHome/HeaderHome";
import Privacy from "./pages/Privacy/Privacy";
import ApplyClosed from "./pages/ApplyClosed/ApplyClosed";
import ScholarshipDetail from "./pages/ScholarshipDetail/ScholarshipDetail";
import { cld } from "./utils/CloudinaryService";
import { AdvancedImage } from "@cloudinary/react";
import ApplicantConsent from "./pages/ApplicantConsent/ApplicantConsent";

const App = () => {
  return (
    <div className="App">
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/past-winners" element={<PastWinners />} />
        <Route path="/judge" element={<Judge />} />
        <Route path="/financials" element={<Financials />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/scholarships" element={<Scholarships />} />
        <Route path="/sponsor" element={<Sponsor />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/applicant-consent" element={<ApplicantConsent />} />
        {/* <Route path="/scholarships/:name" element={<ScholarshipDetail />} /> */}
        <Route path="/scholarships/:pk" element={<ScholarshipDetail />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
