import sponsorImagesAddresses from "../pages/ScholarshipDetail/sponsor-images-address.json";
import axios from "axios";

let imagePaths = [];
let counter = 0;
let baseURL = "https://maxscholars.org/static-images";

export const returnSponsorNamesList = async () => {
  const result = await axios
    .get(
      "https://maxgala.github.io/max-scholarships-images-path/sponsorNamesList.json"
    )
    .then((res) => res.data);
  return result;
};

export const fetchImagePaths = async (type) => {
  if (type == "winner") {
    const result = await axios
      .get(
        "https://maxgala.github.io/max-scholarships-images-path/imagePaths.json"
      )
      .then((res) => res.data);
    return result;
  } else if (type == "sponsor") {
    // Sponsor is also for scholarship
    console.log("Fetching for sponsor list...");
    const result = await axios
      .get(
        "https://maxgala.github.io/max-scholarships-images-path/sponsorImages.json"
      )
      .then((res) => res.data);
    return result;
  } else if (type == "landing-photo") {
    const result = await axios
      .get(
        "https://maxgala.github.io/max-scholarships-images-path/landingPhotoPaths.json"
      )
      .then((res) => res.data);
    return result;
  } else {
    return [];
  }
};

export const findClosestMatch = (
  inputString = "general",
  type = "winner",
  stringArray = []
) => {
  // Normalize the input string: lowercase and replace non-alphanumerics with dashes
  // if (inputString == "Yumnah Afzaal Memorial All Star Scholarship") {
  //   console.log(stringArray);
  // }
  const normalizedInput = inputString
    .toLowerCase()
    .replace(/scholarship/g, "")
    // .replace(/scholarships/g, "")
    .replace(/memorial/g, "")
    .replace(/all/g, "")
    .replace(/star/g, "")
    .replace(/award/g, "")
    .replace(/women/g, "")
    .replace(/excellence/g, "")
    .replace(/muslim/g, "")
    .replace(/newcomers/g, "")
    .replace(/refugees/g, "")
    // .replace(/youth/g, "")
    .replace(/engineering/g, "")
    .replace(/[^a-zA-Z0-9]/g, "-");

  let maxMatchCount = 0;
  let closestMatch = "";

  stringArray.forEach((item) => {
    // Normalize array item
    const normalizedItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-");

    // Count matching segments
    let matchCount = 0;
    normalizedInput.split("-").forEach((segment) => {
      if (normalizedItem.includes(segment) && segment.length > 0) {
        matchCount++;
      }
    });

    // Update closest match if current item has more matches
    if (matchCount > maxMatchCount) {
      maxMatchCount = matchCount;
      closestMatch = item;
    }
  });

  if (type == "winner") {
    return `${baseURL}/past-winners-300px/${closestMatch}`;
  } else if (type == "sponsor") {
    return `${baseURL}/sponsor-photos-350px/${closestMatch}`;
  } else if (type == "landing-photo") {
    return `${baseURL}/landing-photos-1300px/${closestMatch}`;
  } else if (type == "header") {
    return `${baseURL}/headers/${closestMatch}`;
  } else {
    return null;
  }
  // return getOriginalCloudinaryImageUrl(closestMatch);
};

export const returnLandingPhoto = (photoString) =>
  `${baseURL}/landing-photos-1300px/${photoString}`;

export const returnMiscPhoto = (photoString) =>
  `${baseURL}/misc/${photoString}`;

export const returnHeaderPhoto = (photoString) =>
  `${baseURL}/headers/${photoString}`;
