import React, { useState, useEffect } from "react";
import {
  Image,
  Tabs,
  Tab,
  Card,
  Button,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import "./PastWinners.css";
// import impactreport from "../../assets/impact.png";
// import Header from "../../components/Header/Header";
import { getPastWinners } from "../../utils/BackendService";
import imageWinnerFiles from "./imageWinnerFiles.json";
import { findClosestMatch, fetchImagePaths } from "../../utils/StringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCircleInfo,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import HeaderHome from "../../components/HeaderHome/HeaderHome";

const PastWinners = () => {
  const [apiPastWinners, setApiPastWinners] = useState([]);

  useEffect(() => {
    let data;
    const getPastWinnersData = async () => {
      data = await getPastWinners();
      if (data) {
        setApiPastWinners(data.data);
      }
    };
    getPastWinnersData();
    setShow(false);
  }, []);

  useEffect(() => {
    if (apiPastWinners) {
      setYearsArray(
        [...new Set(apiPastWinners?.map((pw) => pw.year))].reverse()
      );
    }
  }, [apiPastWinners]);

  // Grab all the years from the data and make a list of the unique numbers
  const [yearsArray, setYearsArray] = useState([]);

  const [show, setShow] = useState(false);

  const [selectedWinner, setSelectedWinner] = useState([
    {
      id: 1,
      name: "Abdulkarim Muhaseen",
      scholarship_name:
        "Mohammad Humayun Zafar Scholarship for Sciences and Engineering",
      biography:
        "Abdulkarim grew up in humble beginnings which largely shaped his drive. During his undergraduate degree in Medical Physics, he held office for several years as president of his program's student union, was involved in fund raising money for global educational initiatives and wrote on social justice issues in a local community publication.  Abdulkarim is currently at U of T medical school, and is intending to pursue a career in global health and health journalism. In his free time you will likely find him practicing gymnastics or playing basketball, trying to speak a foreign language or experimenting in the kitchen to his mother's disapproval.",
      photo:
        "scholarship_winners_pictures/2016_-_Abdulkarim_Muhaseen_2-cropped-1.png",
      year: 2016,
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    const winner = apiPastWinners.filter((w) => w.id == id);
    setSelectedWinner(winner);
    setShow(true);
  };

  const [winnerImagePaths, setWinnerImagePaths] = useState([]);

  // const callFetchImagePaths = async () => {
  //   const data = await fetchImagePaths("winner");
  //   if (data) {
  //     setWinnerImagePaths(data);
  //   }
  // };

  // useEffect(() => {
  //   callFetchImagePaths();
  // }, []);

  return (
    <div>
      {/* <Header title="" backgroundUrl="Past-Winners.png" /> */}
      {/* <HeaderImage backgroundUrl="Past-Winners.png" /> */}
      <HeaderHome backgroundUrl="Past-Winners.png" />
      <div style={{ textAlign: "center", marginTop: "3em" }}>
        <h1
          style={{
            color: "#0260A8",
            fontWeight: "bold",
          }}
        >
          {" "}
          Past Winners{" "}
        </h1>
      </div>
      <div className="scholarship-info text-center my-4">
        <p>
          <strong>
            Scroll through and click to learn more about the past winners of the
            MAX Scholarship Fund (MSF).
          </strong>{" "}
          These students are well-rounded High School and Post Secondary
          students who are eager to make an impact, learn and create. Winners
          are students who have contributed to their communities and Canada at
          large, achieved above-average marks, and are recognized by their
          peers.
        </p>
        <p>
          MAX Scholarship Fund is committed to diversity and recognizes the
          multivalence of identities that make up the Muslim community including
          race, gender, and persons with a disability. Our scholarship program
          provides diverse opportunities for students from diverse backgrounds
          to receive financial aid for their education and growth.
        </p>
      </div>
      <div className="centered-tabs-container pw">
        <Tabs
          // defaultActiveKey={yearsArray[0]}
          defaultActiveKey="2023"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {yearsArray
            ? yearsArray.map((year, i) => (
                <Tab eventKey={year} title={year} key={i}>
                  <div className="centered-cards centered-tabs pw" key={i}>
                    {apiPastWinners.length > 0 ? (
                      <>
                        {apiPastWinners
                          .filter((w) => w.year == year)
                          .map((w) => (
                            <div
                              className="winner-card pw"
                              onClick={() => handleShow(w.id)}
                            >
                              <div className="winner-img-wrapper">
                                {/* <img
                                  className="winner-img"
                                  src={findClosestMatch(
                                    w.name,
                                    "winner",
                                    winnerImagePaths
                                  )}
                                /> */}
                                <img
                                  className="winner-img"
                                  src={"/media/" + w.photo}
                                />
                              </div>
                              <FontAwesomeIcon
                                // icon={faInfoCircle}
                                icon={faCircleInfo}
                                // icon={faGraduationCap}
                                className="info-icon"
                              />
                              <div className="winner-text-wrapper">
                                <p className="winner-name">{w.name}</p>
                                <p className="winner-scholarship">
                                  {w.scholarship_name}
                                </p>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : null}
                  </div>
                </Tab>
              ))
            : null}
        </Tabs>
      </div>
      {selectedWinner ? (
        <Modal show={show} onHide={handleClose} className="winner-modal-box">
          <Modal.Header closeButton>
            <Modal.Title>
              <p className="winner-modal-name">{selectedWinner[0].name}</p>
              <p className="winner-modal-scholarship">
                {selectedWinner[0].scholarship_name}
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="winner-modal-container">
              {/* <img
                className="winner-modal-img"
                // src={findClosestMatch(selectedWinner[0].name, imageWinnerFiles)}
                src={findClosestMatch(
                  selectedWinner[0].name,
                  "winner",
                  winnerImagePaths
                )}
              /> */}
              <img
                className="winner-modal-img"
                src={"/media/" + selectedWinner[0].photo}
              />
              <p className="winner-modal-bio">{selectedWinner[0].biography}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

export default PastWinners;
