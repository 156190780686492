import React from "react";
import "./FAQ.css";

const Contact = () => {
    return (
        <div style={{backgroundColor: "white"}}>
            <iframe id="JotFormIFrame-210013716344039" title="Contact MAX Scholarship Fund (MSF)"
                    allowTransparency="true" allowFullScreen="true"
                    allow="geolocation; microphone; camera" src="https://form.jotform.com/210013716344039" frameBorder="0"
                    style={{minWidth: "100%", height:"1200px", border:"none"}} scrolling="yes"></iframe>
        </div>
    );
};
export default Contact;
