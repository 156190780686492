import React from "react";
import "./FAQ.css";

const FAQCard = ({ faq, index, toggleFAQ }) => {
  const renderAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return answer.map((line, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: line }}></div>
      ));
    } else {
      return <div dangerouslySetInnerHTML={{ __html: answer }}></div>;
    }
  };

  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{renderAnswer(faq.answer)}</div>
    </div>
  );
};

export default FAQCard;
